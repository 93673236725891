import axios from 'axios';
import { toast } from 'react-toastify';

const interceptor = () => {

    // request interceptor
    axios.interceptors.request.use( (request) => {
            return request;
        } , (error) => {
            toast.error(error.message);
            return Promise.reject(error);
        }
    );
    
    // response interceptor
    axios.interceptors.response.use( (response) => {
            return response;
        }, (error) => {
            // console.log("Response InterCeptor" , error.response);
            let errorMessage = error.response && error.response.data ? error.response.data.message : error.message;
            if(errorMessage === "The given data was invalid."){
                let errorMessageArr = Object.values(error.response.data.errors)[0]
                for(let i=0 ; i < errorMessageArr.length ; i++){
                    toast.error(errorMessageArr[i] , { hideProgressBar: true, position: "top-center", autoClose: 5000 });
                }
            } else{
                toast.error(errorMessage , { hideProgressBar: true, position: "top-center", autoClose: 5000 });
            }
            return Promise.reject(error);
        }
    );
}

export default { interceptor };